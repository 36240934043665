<section
  id="click-botella"
  class="{{ 'botella' + currentIndex }}"
  *ngIf="currentProduct"
  [style]="getStyles()"
>
  <div class="container">
    <div class="centrar">
      <div class="box-master">
        <h3>{{ currentProduct.title }}</h3>
        <h2>{{ currentProduct.subtitle }}</h2>
        <p [innerHTML]="currentProduct.description"></p>
        <div class="verde2">
          <a class="esquinas" [href]="currentProduct.link">{{
            config.ctaText
          }}</a>
        </div>
      </div>
    </div>
    <div class="botellas">
      <img
        *ngFor="let item of config.products; let index = index"
        [src]="item.sampleImage"
        alt=""
        [attr.data-number]="index"
        (click)="onClick(index)"
        [ngClass]="{ active: currentIndex === index + 1 }"
      />
    </div>
  </div>
  <img src="" alt="" class="precarga" style="display: none" />
</section>
<section>
  <div class="container">
      <div class="box-master-mobile">
        <h3>{{ currentProduct.title }}</h3>
        <h2>{{ currentProduct.subtitle }}</h2>
        <p [innerHTML]="currentProduct.description"></p>
        <div class="verde2">
          <a class="esquinas" [href]="currentProduct.link">{{
            config.ctaText
          }}</a>
        </div>
      </div>
  </div>
</section>
