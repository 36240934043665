import { Component, ElementRef } from '@angular/core';
import { DataInitialValueServiceService } from 'app/modules/core/services/data-initial-value-service.service';
import { ProductCardSliderConfiguration } from '../../models/product-card-slider-configuration.model';

@Component({
  selector: 'app-product-card-container',
  templateUrl: './product-card-container.component.html',
  styleUrls: ['./product-card-container.component.css']
})
export class ProductCardContainerComponent {
  public config: ProductCardSliderConfiguration;

  constructor(private elementRef: ElementRef,
    private dataInitialValue: DataInitialValueServiceService ) {
      this.config = this.dataInitialValue.Parse<ProductCardSliderConfiguration>(this.elementRef);
    }

}
