import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ProductCardContainerComponent } from './components/product-card-container/product-card-container.component';
import { ProductCardComponent } from './components/product-card/product-card.component';

@NgModule({
  declarations: [ProductCardContainerComponent, ProductCardComponent],
  imports: [SharedModule],
  providers: [
    {
      provide: 'components',
      useValue: [ProductCardContainerComponent, ProductCardComponent],
      multi: true,
    },
  ],
  entryComponents: [ProductCardContainerComponent],
})
export class ProductCardSliderModule {}
