import { Component, Input, OnInit } from '@angular/core';
import { ProductCardSliderConfiguration } from '../../models/product-card-slider-configuration.model';
import { Product } from '../../models/product.model';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.css'],
})
export class ProductCardComponent implements OnInit {
  @Input() config: ProductCardSliderConfiguration;
  public currentProduct: Product;
  public currentIndex: number;

  constructor() {
    this.currentIndex = 1;
  }

  ngOnInit(): void {
    this.internalInit();
  }

  private internalInit(): void {
    if (this.config.products) {
      this.currentProduct = this.config.products[0];
    }
  }

  public onClick(index: number): void {
    const product = this.config.products[index];
    if (product) {
      this.currentProduct = product;
      this.currentIndex = index + 1;
    }
  }

  getStyles() {
    return `--ban: url(${this.currentProduct.desktopImage});
    --ban-mob: url(${this.currentProduct.desktopImage})`;
  }
}
